(function ($) {
    const form = $('.achievements-form');
    const yearList = form.find('.year-list');
    yearList.on('change', function () {
        form.trigger('submit');
    });
})(jQuery);
(function ($) {
    const form = $('.apply-job-form');
    const feedback = function (message) {
        if (message) {
            $('#feedback-message').text(message);
            $('#feedback-modal').modal('show');
        }
    }
    form.on('submit', function (e) {
        const that = this;
        e.preventDefault();
        const btnText = $(this).find('button[type="submit"]').text();
        $(this).find('button[type="submit"]').prop('disabled', true).text('Sending...');

        // Create FormData object to handle file uploads
        const formData = new FormData(this);

        $.ajax({
            url: $(this).attr('action'),
            type: 'POST',
            data: formData,
            processData: false, // Prevent jQuery from automatically processing data
            contentType: false, // Prevent jQuery from setting contentType
            dataType: 'json', // Specify the expected data type of the response
            success: function (result) {
                if (result.status) {
                    $('#apply-job-modal').modal('hide');
                }
                feedback(result.message);
                $(that).find('button[type="submit"]').prop('disabled', false).text(btnText);
            },
            error: function (xhr, status, error) {
                console.error(xhr, status, error);
                // Handle error feedback if necessary
            }
        });

        return false;
    });
    $('#apply-job-modal').on('shown.bs.modal', function (e) {
        const button = $(e.relatedTarget);
        form.find('.position').val(button.data('position'));
        form.find('[name=position]').val(button.data('position'));
    });

    const captcha = $('form img.captcha');
    const src = captcha.attr('src');
    $('form .captcha').on('click', function () {
        captcha.attr('src', src + '?s=' + Math.random());
    });
})(jQuery);

(function ($) {
    const form = $('.contact-form');
    const feedback = function (message) {
        if (message) {
            $('#feedback-message').text(message);
            $('#feedback-modal').modal('show');
        }
    }
    form.on('submit', function (e) {
        const that = this;
        e.preventDefault();
        const btnText = $(this).find('button[type="submit"]').text();
        $(this).find('button[type="submit"]').prop('disabled', true).text('Sending...');
        $.post($(this).attr('action'), $(this).serialize(), function (result) {
            if (result.status) {
                $('#contact-form-modal').modal('hide');
            }
            feedback(result.message);
            $(that).find('button[type="submit"]').prop('disabled', false).text(btnText);
        }, 'json');

        // const message = 'Your information has been submitted, we will communicate with you as soon as possible, please wait patiently, thank you.';
        // feedback(message);

        return false;
    });
    const captcha = $('form img.captcha');
    const src = captcha.attr('src');
    $('form .captcha').on('click', function () {
        captcha.attr('src', src + '?s=' + Math.random());
    });
})(jQuery);
(function ($) {
    // 使用事件委托监听所有文件上传input的change事件
    $(document).on('change', 'input[type="file"]', function () {
        const fileName = $(this).val().split('\\').pop(); // 将变量声明为const
        // 找到对应的文件名span元素并显示文件名
        const mockInput = $(this).parents('.file-upload-component').find('.file-name');
        const message = mockInput.data('message');
        mockInput.text(fileName || message);
    });
})(jQuery);

(function () {
    const wrap = $('.page-achievements-content');
    const rows = wrap.find('table tbody tr');
    const loadBtn = wrap.find('#load-more');
    const noMoreBtn = wrap.find('.no-more');

    let page = 1;
    const perPage = 4;
    const load = function (start, end) {
        rows.each(function (i, r) {
            if (i >= start && i < end) {
                $(this).removeClass('d-none');
            }
        });
        if (start) {
            $('html, body').animate({scrollTop: rows.eq(start).offset().top});
        }
    }

    loadBtn.on('click', function (e) {
        e.preventDefault();
        const start = (page - 1) * perPage;
        const end = start + perPage;
        load(start, end);
        if (end > rows.length) {
            loadBtn.toggleClass('d-none');
            noMoreBtn.toggleClass('d-none');
        }
        page++;
        return false;
    }).trigger('click');
})();
// 统一列表高度

// (function ($) {
//     let height = 0;
//     const textBoxes = $('.data-grid').find('.text-wrap');
//     textBoxes.each(function () {
//         if ($(this).outerHeight() > height) {
//             height = $(this).outerHeight();
//         }
//     });
//     textBoxes.css({height: height + 'px'});
//
//     function getBodyClass(fullPath) {
//         let className = '';
//
//         if (fullPath) {
//             className = fullPath.replace(/^.*[\\\/]/, '').replace(/[.].*$/, '');
//         }
//
//         if (className === '' || className === 'index') {
//             className = 'home';
//         }
//
//         return className;
//     }
//
//     $(document).find('body').addClass(getBodyClass(location.pathname));
// })(jQuery);

(function ($) {
    $('.slide-toggle').on('click', function () {
        const slide = $(this).parent();
        const wrap = slide.find('.slide-content-wrap');
        const content = slide.find('.slide-content');
        slide.toggleClass('open');
        slide.find('.toggle i').toggleClass('.bi-chevron-compact-down bi-chevron-compact-up');
        if (slide.hasClass('open')) {
            wrap.animate({height: content.outerHeight()});
        } else {
            wrap.animate({height: 0});
        }
    });
    $('.offices-list > .office-item:first').find('.slide-toggle').trigger('click');
})(jQuery);

(function ($) {
    const form = $('.survey-form');
    const feedback = function (message) {
        if (message) {
            $('#feedback-message').text(message);
            $('#feedback-modal').modal('show');
        }
    }
    form.on('submit', function (e) {
        const that = this;
        e.preventDefault();
        const btnText = $(this).find('button[type="submit"]').text();
        $(this).find('button[type="submit"]').prop('disabled', true).text('Sending...');
        $.post($(this).attr('action'), $(this).serialize(), function (result) {
            if (result.status) {
                $('#survey-form-modal').modal('hide');
            }
            feedback(result.message);
            $(that).find('button[type="submit"]').prop('disabled', false).text(btnText);
        }, 'json');

        // const message = 'Your information has been submitted, we will communicate with you as soon as possible, please wait patiently, thank you.';
        // feedback(message);

        return false;
    });
    const captcha = $('form img.captcha');
    const src = captcha.attr('src');
    $('form .captcha').on('click', function () {
        captcha.attr('src', src + '?s=' + Math.random());
    });
})(jQuery);
(function (sp) {
    const swiper1 = new sp('.slideshow-home1 .swiper', {
        loop: true,
        autoplay: {
            delay: 5000
        },
        pagination: {
            el: '.slideshow-home1 .swiper-pagination',
            clickable: true
        },
    });

    const swiper2 = new sp('.slideshow-achievements .swiper', {
        loop: true,
        slidesPerView: 2,
        spaceBetween: 10,
        autoplay: {
            delay: 3000
        },
        breakpoints: {
            640: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 5,
                spaceBetween: 100,
            },
        },
    });

    const swiper3 = new sp('.slideshow-scope-services .swiper', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
            delay: 4000
        },
        navigation: {
            nextEl: '.block-scope-services .swiper-button-next',
            prevEl: '.block-scope-services .swiper-button-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 15,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1200: {
                slidesPerView: 'auto',
                spaceBetween: 30,
            },
        },
    });

    const swiper4 = new sp('.slideshow-client-testimonials .swiper', {
        // If we need pagination
        slidesPerView: 1,
        spaceBetween: 2,
        autoplay: {
            delay: 4000
        },
        pagination: {
            el: '.slideshow-client-testimonials .swiper-pagination',
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return '<span class="' + currentClass + '"></span>' +
                    ' / ' +
                    '<span class="' + totalClass + '"></span>';
            }
        },
        navigation: {
            nextEl: '.slideshow-client-testimonials .swiper-button-next',
            prevEl: '.slideshow-client-testimonials .swiper-button-prev',
        },
    });

    const swiper5 = new sp('.slideshow-newsletter .swiper', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
            delay: 3000
        },
        navigation: {
            nextEl: '.slideshow-newsletter .swiper-button-next',
            prevEl: '.slideshow-newsletter .swiper-button-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 15,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
    });

    const swiper6 = new sp('.slideshow-related .swiper', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
            delay: 3000
        },
        navigation: {
            nextEl: '.slideshow-related .swiper-button-next',
            prevEl: '.slideshow-related .swiper-button-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 80,
            },
        },
    });

    // $('.achievement-slideshow').each(function (i, n) {
    //     new sp('.achievement-slideshow' + (i + 1) + ' .swiper', {
    //         loop: true,
    //         slidesPerView: 1,
    //         spaceBetween: 0,
    //         autoplay: {
    //             delay: Math.floor(Math.random() * (7000 - 1000) + 1000)
    //         },
    //         pagination: {
    //             el: '.achievement-slideshow' + (i + 1) + ' .swiper-pagination',
    //             clickable: true
    //         },
    //     });
    // });
})(Swiper);